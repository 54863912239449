import React, { useState, useEffect } from 'react';

function TextInputModal({ isOpen, onClose, onSave, initialText }) {
  const [text, setText] = useState(initialText || '');

  useEffect(() => {
    setText(initialText || '');
  }, [initialText]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Enter Amenity Name</h3>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          autoFocus
          placeholder="Enter name..."
        />
        <div className="modal-buttons">
          <button onClick={() => onSave(text)}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default TextInputModal;