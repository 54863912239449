import React, { useState } from 'react';
import Grid from './components/Grid';
import Toolbar from './components/Toolbar';
import TextInputModal from './components/TextInputModal';
import './App.css';

function App() {
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  
  const handleDrop = (element, position) => {
    setElements([...elements, {
      id: Date.now(),
      type: element.type,
      boxType: element.boxType,
      color: element.color,
      x: position.x,
      y: position.y,
      width: element.width,
      height: element.height,
      rotation: 0,
      label: element.boxType === 'amenity' ? 'AMENITY' : ''
    }]);
  };

  const handleElementsUpdate = (newElements) => {
    setElements(newElements);
  };

  const handleElementSelect = (elementId) => {
    const element = elements.find(el => el.id === elementId);
    if (element && element.boxType === 'amenity') {
      setSelectedElement(element);
      setIsModalOpen(true);
    }
  };

  const handleTextSave = (newText) => {
    const newElements = elements.map(el => 
      el.id === selectedElement.id ? { ...el, label: newText } : el
    );
    setElements(newElements);
    setIsModalOpen(false);
    setSelectedElement(null);
  };

  const generateSVGText = () => {
    let svgText = '<svg width="1000" height="600" xmlns="http://www.w3.org/2000/svg">\n';
    
    elements.forEach((element) => {
      const rotation = element.rotation ? 
        `transform="rotate(${element.rotation} ${element.x + element.width/2} ${element.y + element.height/2})"` : '';

      switch(element.type) {
        case 'box':
          svgText += `  <rect x="${element.x}" y="${element.y}" width="${element.width}" height="${element.height}" fill="#4CAF50" stroke="black" stroke-width="3" ${rotation}/>\n`;
          break;
        
        case 'track':
          svgText += `  <g ${rotation}>\n`;
          svgText += `    <line x1="${element.x}" y1="${element.y}" x2="${element.x + element.width}" y2="${element.y}" stroke="#333" stroke-width="4"/>\n`;
          svgText += `    <line x1="${element.x}" y1="${element.y + 10}" x2="${element.x + element.width}" y2="${element.y + 10}" stroke="#333" stroke-width="4"/>\n`;
          // Add sleepers
          for (let i = 0; i < Math.floor(element.width / 20); i++) {
            svgText += `    <line x1="${element.x + (i * 20)}" y1="${element.y - 5}" x2="${element.x + (i * 20)}" y2="${element.y + 15}" stroke="#666" stroke-width="2"/>\n`;
          }
          svgText += `    <text x="${element.x + element.width/2}" y="${element.y + 30}" font-family="Arial" font-size="16" fill="black" text-anchor="middle">RAILWAY TRACK</text>\n`;
          svgText += `  </g>\n`;
          break;
        
        case 'flyover':
          svgText += `  <g ${rotation}>\n`;
          for (let i = 0; i < Math.floor(element.height / 10); i++) {
            const y = element.y + (i * 10);
            svgText += `    <line x1="${element.x}" y1="${y}" x2="${element.x + element.width}" y2="${y}" stroke="#333" stroke-width="2"/>\n`;
          }
          svgText += `    <text x="${element.x + element.width/2}" y="${element.y + element.height + 20}" font-family="Arial" font-size="16" fill="black" text-anchor="middle">FLYOVER</text>\n`;
          svgText += `  </g>\n`;
          break;
      }
    });

    svgText += '</svg>';
    return svgText;
  };

  const handleSVGImport = (svgContent) => {
    try {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
      const newElements = [];
      let id = Date.now();

      // Parse rectangles (boxes)
      svgDoc.querySelectorAll('rect').forEach(rect => {
        const x = parseFloat(rect.getAttribute('x'));
        const y = parseFloat(rect.getAttribute('y'));
        const width = parseFloat(rect.getAttribute('width'));
        const height = parseFloat(rect.getAttribute('height'));
        const fill = rect.getAttribute('fill');
        const transform = rect.getAttribute('transform');
        
        // Find associated text
        const textElement = findAssociatedText(svgDoc, x + width/2, y + height/2);
        
        // Determine box type based on size and color
        const isPlatform = width > 500; // Assuming platforms are wider
        
        newElements.push({
          id: id++,
          type: 'box',
          boxType: isPlatform ? 'platform' : 'amenity',
          x,
          y,
          width,
          height,
          color: fill,
          rotation: parseRotation(transform),
          label: textElement?.textContent || (isPlatform ? 'PLATFORM' : 'AMENITY')
        });
      });

      // Parse railway tracks (looking for pairs of parallel lines)
      const processedLines = new Set();
      svgDoc.querySelectorAll('line').forEach(line => {
        if (processedLines.has(line)) return;

        const x1 = parseFloat(line.getAttribute('x1'));
        const y1 = parseFloat(line.getAttribute('y1'));
        const x2 = parseFloat(line.getAttribute('x2'));
        const y2 = parseFloat(line.getAttribute('y2'));
        const transform = line.getAttribute('transform');

        // Look for parallel line (part of the same track)
        const parallelLine = Array.from(svgDoc.querySelectorAll('line')).find(otherLine => {
          if (otherLine === line || processedLines.has(otherLine)) return false;
          
          const otherY1 = parseFloat(otherLine.getAttribute('y1'));
          const otherY2 = parseFloat(otherLine.getAttribute('y2'));
          
          // Check if lines are parallel and close to each other
          return Math.abs(otherY1 - y1) <= 20 && 
                 Math.abs(otherY2 - y2) <= 20 &&
                 Math.abs(parseFloat(otherLine.getAttribute('x1')) - x1) < 5 &&
                 Math.abs(parseFloat(otherLine.getAttribute('x2')) - x2) < 5;
        });

        if (parallelLine) {
          // This is a railway track (two parallel lines)
          processedLines.add(line);
          processedLines.add(parallelLine);
          
          newElements.push({
            id: id++,
            type: 'track',
            x: x1,
            y: Math.min(y1, parseFloat(parallelLine.getAttribute('y1'))),
            width: x2 - x1,
            height: 20,
            rotation: parseRotation(transform)
          });
        }
      });

      // Parse flyovers (groups of horizontal lines)
      svgDoc.querySelectorAll('g').forEach(group => {
        const lines = Array.from(group.querySelectorAll('line'))
          .filter(line => !processedLines.has(line));
        
        if (lines.length > 2) { // Assuming groups with multiple lines are flyovers
          const firstLine = lines[0];
          const x = parseFloat(firstLine.getAttribute('x1'));
          const y = parseFloat(firstLine.getAttribute('y1'));
          const width = parseFloat(firstLine.getAttribute('x2')) - x;
          const height = parseFloat(lines[lines.length - 1].getAttribute('y1')) - y;
          const transform = group.getAttribute('transform');

          lines.forEach(line => processedLines.add(line));

          newElements.push({
            id: id++,
            type: 'flyover',
            x,
            y,
            width,
            height,
            rotation: parseRotation(transform)
          });
        }
      });

      setElements(newElements);
    } catch (error) {
      console.error('Error parsing SVG:', error);
      alert('Error importing SVG. Please make sure it\'s in the correct format.');
    }
  };

  const findAssociatedText = (svgDoc, x, y) => {
    let closestText = null;
    let minDistance = Infinity;
    
    svgDoc.querySelectorAll('text').forEach(text => {
      const textX = parseFloat(text.getAttribute('x'));
      const textY = parseFloat(text.getAttribute('y'));
      const distance = Math.sqrt(
        Math.pow(textX - x, 2) + Math.pow(textY - y, 2)
      );
      
      if (distance < minDistance) {
        minDistance = distance;
        closestText = text;
      }
    });

    return minDistance < 100 ? closestText : null;
  };

  const parseRotation = (transform) => {
    if (!transform) return 0;
    const match = transform.match(/rotate\(([^)]+)\)/);
    if (match) {
      const [angle] = match[1].split(' ');
      return parseFloat(angle);
    }
    return 0;
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generateSVGText());
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy');
      console.error('Failed to copy text: ', err);
    }
  };

  const handleDownload = () => {
    const svgText = generateSVGText();
    const blob = new Blob([svgText], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'railway-layout.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="app">
      <Toolbar onSVGImport={handleSVGImport} />
      <div className="workspace">
        <Grid 
          width={800} 
          height={600} 
          gridSize={50} 
          elements={elements} 
          onDrop={handleDrop}
          onElementsUpdate={handleElementsUpdate}
          onElementSelect={handleElementSelect}
        />
        <div className="svg-output">
          <div className="svg-output-header">
            <h3>SVG Output</h3>
            <div className="svg-output-buttons">
              <button 
                className="action-button"
                onClick={handleCopy}
              >
                {copySuccess || 'Copy SVG'}
              </button>
              <button 
                className="action-button download-button"
                onClick={handleDownload}
              >
                Download SVG
              </button>
            </div>
          </div>
          <pre>
            <code>
              {generateSVGText()}
            </code>
          </pre>
        </div>
      </div>
      <TextInputModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleTextSave}
        initialText={selectedElement?.label}
      />
    </div>
  );
}

export default App;