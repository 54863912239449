import React, { useState, useEffect } from 'react';

function Grid({ width, height, gridSize, elements, onDrop, onElementsUpdate, onElementSelect }) {
  const [resizing, setResizing] = useState(null);
  const [rotating, setRotating] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [dragging, setDragging] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);

  // Add keyboard event listener for delete
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Delete' && selectedElement) {
        deleteElement(selectedElement);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedElement]);

  // Add click listener to close context menu
  useEffect(() => {
    const handleClick = () => setContextMenu(null);
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  const handleContextMenu = (e, elementId) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      elementId
    });
  };

  const deleteElement = (elementId) => {
    const newElements = elements.filter(el => el.id !== elementId);
    onElementsUpdate(newElements);
    setSelectedElement(null);
    setContextMenu(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    try {
      const elementData = e.dataTransfer.getData('text');
      if (!elementData) {
        console.log('No element data found');
        return;
      }
      
      const element = JSON.parse(elementData);
      const rect = e.currentTarget.getBoundingClientRect();
      const x = Math.floor((e.clientX - rect.left) / gridSize) * gridSize;
      const y = Math.floor((e.clientY - rect.top) / gridSize) * gridSize;
      
      onDrop(element, { x, y });
      
      // Clear any ongoing drag operation
      setDragging(null);
      
      // If it's an amenity, trigger the select after a short delay
      if (element.boxType === 'amenity') {
        setTimeout(() => {
          const newElement = elements[elements.length - 1];
          if (newElement) {
            onElementSelect(newElement.id);
          }
        }, 100);
      }
    } catch (error) {
      console.error('Error handling drop:', error);
    }
  };

  const handleElementClick = (e, elementId) => {
    e.stopPropagation();
    const element = elements.find(el => el.id === elementId);
    setSelectedElement(elementId);
    
    // Only trigger select for existing amenities, not during drag
    if (element && element.boxType === 'amenity' && !dragging) {
      onElementSelect(elementId);
    }
  };

  const startDrag = (e, elementId) => {
    e.stopPropagation();
    const element = elements.find(el => el.id === elementId);
    if (!element) return;

    setDragging({
      id: elementId,
      startX: e.clientX,
      startY: e.clientY,
      originalX: element.x,
      originalY: element.y
    });
  };

  const startResize = (e, elementId, handle) => {
    e.stopPropagation();
    setResizing({
      id: elementId,
      handle,
      startX: e.clientX,
      startY: e.clientY
    });
  };

  const handleMouseMove = (e) => {
    if (!resizing) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    
    const snappedX = Math.floor(mouseX / gridSize) * gridSize;
    const snappedY = Math.floor(mouseY / gridSize) * gridSize;

    const element = elements.find(el => el.id === resizing.id);
    if (!element) return;

    const newElements = elements.map(el => {
      if (el.id === resizing.id) {
        let newWidth = el.width;
        let newHeight = el.height;
        let newX = el.x;
        let newY = el.y;

        switch (resizing.handle) {
          case 'se':
            newWidth = Math.max(gridSize, snappedX - el.x);
            newHeight = Math.max(gridSize, snappedY - el.y);
            break;
          case 'sw':
            newWidth = Math.max(gridSize, (el.x + el.width) - snappedX);
            newHeight = Math.max(gridSize, snappedY - el.y);
            newX = Math.min(snappedX, el.x + el.width - gridSize);
            break;
          case 'ne':
            newWidth = Math.max(gridSize, snappedX - el.x);
            newHeight = Math.max(gridSize, el.y + el.height - snappedY);
            newY = Math.min(snappedY, el.y + el.height - gridSize);
            break;
          case 'nw':
            newWidth = Math.max(gridSize, (el.x + el.width) - snappedX);
            newHeight = Math.max(gridSize, el.y + el.height - snappedY);
            newX = Math.min(snappedX, el.x + el.width - gridSize);
            newY = Math.min(snappedY, el.y + el.height - gridSize);
            break;
          default:
            break;
        }

        return {
          ...el,
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight
        };
      }
      return el;
    });

    onElementsUpdate(newElements);
  };

  const handleMouseUp = () => {
    setResizing(null);
  };

  const handleBackgroundClick = () => {
    setSelectedElement(null);
  };

  const renderResizeHandles = (element) => {
    if (element.id !== selectedElement) return null;

    const handles = [
      { cursor: 'nw-resize', position: 'nw', x: 0, y: 0 },
      { cursor: 'ne-resize', position: 'ne', x: element.width, y: 0 },
      { cursor: 'sw-resize', position: 'sw', x: 0, y: element.height },
      { cursor: 'se-resize', position: 'se', x: element.width, y: element.height }
    ];

    return handles.map(handle => (
      <circle
        key={handle.position}
        cx={element.x + handle.x}
        cy={element.y + handle.y}
        r={5}
        fill="white"
        stroke="#666"
        strokeWidth={1}
        style={{ cursor: handle.cursor }}
        onMouseDown={(e) => startResize(e, element.id, handle.position)}
      />
    ));
  };

  const startRotate = (e, elementId) => {
    e.stopPropagation();
    const element = elements.find(el => el.id === elementId);
    setRotating({
      id: elementId,
      startAngle: element.rotation || 0,
      startX: e.clientX,
      startY: e.clientY
    });
  };

  const handleRotate = (e) => {
    if (!rotating) return;

    const element = elements.find(el => el.id === rotating.id);
    if (!element) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const centerX = element.x + element.width / 2;
    const centerY = element.y + element.height / 2;
    
    const angle = Math.atan2(
      e.clientY - rect.top - centerY,
      e.clientX - rect.left - centerX
    ) * (180 / Math.PI);

    const snappedAngle = Math.round(angle / 45) * 45;

    const newElements = elements.map(el => {
      if (el.id === rotating.id) {
        return {
          ...el,
          rotation: snappedAngle
        };
      }
      return el;
    });

    onElementsUpdate(newElements);
  };

  const renderTrack = (element) => {
    const lineSpacing = 10;
    return (
      <>
        <line
          x1={element.x}
          y1={element.y}
          x2={element.x + element.width}
          y2={element.y}
          stroke="#333"
          strokeWidth={4}
        />
        <line
          x1={element.x}
          y1={element.y + lineSpacing}
          x2={element.x + element.width}
          y2={element.y + lineSpacing}
          stroke="#333"
          strokeWidth={4}
        />
        {Array.from({ length: Math.floor(element.width / 20) }).map((_, i) => (
          <line
            key={`sleeper-${i}`}
            x1={element.x + (i * 20)}
            y1={element.y - 5}
            x2={element.x + (i * 20)}
            y2={element.y + lineSpacing + 5}
            stroke="#666"
            strokeWidth={2}
          />
        ))}
      </>
    );
  };

  const renderFlyover = (element) => {
    const lineSpacing = 10;
    const numLines = Math.floor(element.height / lineSpacing);
    
    return (
      <>
        {Array.from({ length: numLines }).map((_, i) => {
          const y = element.y + (i * lineSpacing);
          return (
            <line
              key={`flyover-line-${i}`}
              x1={element.x}
              y1={y}
              x2={element.x + element.width}
              y2={y}
              stroke="#333"
              strokeWidth={2}
            />
          );
        })}
      </>
    );
  };

  const renderGridNumbers = () => (
    <>
      {Array.from({ length: width / gridSize + 1 }).map((_, i) => (
        <React.Fragment key={`grid-line-${i}`}>
          <line 
            x1={i * gridSize} 
            y1={0} 
            x2={i * gridSize} 
            y2={height} 
            stroke="#ddd" 
          />
          {i % 2 === 0 && (
            <text 
              x={i * gridSize} 
              y={20} 
              fontSize="12"
              style={{ userSelect: 'none', pointerEvents: 'none' }}
            >
              {i * gridSize}
            </text>
          )}
        </React.Fragment>
      ))}
      {Array.from({ length: height / gridSize + 1 }).map((_, i) => (
        <React.Fragment key={`grid-line-h-${i}`}>
          <line 
            x1={0} 
            y1={i * gridSize} 
            x2={width} 
            y2={i * gridSize} 
            stroke="#ddd" 
          />
          {i % 2 === 0 && (
            <text 
              x={5} 
              y={i * gridSize + 15} 
              fontSize="12"
              style={{ userSelect: 'none', pointerEvents: 'none' }}
            >
              {i * gridSize}
            </text>
          )}
        </React.Fragment>
      ))}
    </>
  );

  const handleDrag = (e) => {
    if (!dragging) return;

    const element = elements.find(el => el.id === dragging.id);
    if (!element) return;

    const dx = e.clientX - dragging.startX;
    const dy = e.clientY - dragging.startY;
    
    const newX = dragging.originalX + dx;
    const newY = dragging.originalY + dy;

    const snappedX = element.type === 'box' ? Math.round(newX / gridSize) * gridSize : newX;
    const snappedY = element.type === 'box' ? Math.round(newY / gridSize) * gridSize : newY;

    const newElements = elements.map(el => {
      if (el.id === dragging.id) {
        return {
          ...el,
          x: snappedX,
          y: snappedY
        };
      }
      return el;
    });

    onElementsUpdate(newElements);
  };

  const renderElement = (element) => (
    <g 
      key={element.id} 
      transform={`rotate(${element.rotation || 0} ${element.x + element.width/2} ${element.y + element.height/2})`}
      onClick={(e) => handleElementClick(e, element.id)}
      onContextMenu={(e) => handleContextMenu(e, element.id)}
      onMouseDown={(e) => {
        if (e.button === 0) { // Left click only
          handleElementClick(e, element.id);
          startDrag(e, element.id);
        }
      }}
      style={{ cursor: dragging?.id === element.id ? 'grabbing' : 'grab' }}
    >
      {element.type === 'box' && (
        <g>
          <rect
            x={element.x}
            y={element.y}
            width={element.width}
            height={element.height}
            fill={element.color}
            stroke="black"
            strokeWidth="2"
            opacity={element.boxType === 'amenity' ? 0.9 : 0.7}
          />
          {element.boxType === 'platform' && (
            <text
              x={element.x + element.width/2}
              y={element.y + element.height/2}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="black"
              fontSize="16"
              fontFamily="Arial"
            >
              PLATFORM
            </text>
          )}
          {element.boxType === 'amenity' && (
            <text
              x={element.x + element.width/2}
              y={element.y + element.height/2}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="black"
              fontSize="12"
              fontFamily="Arial"
            >
              {element.label || 'AMENITY'}
            </text>
          )}
        </g>
      )}
      {element.type === 'track' && renderTrack(element)}
      {element.type === 'flyover' && renderFlyover(element)}
      {renderResizeHandles(element)}
      {element.id === selectedElement && (
        <circle
          cx={element.x + element.width/2}
          cy={element.y - 20}
          r={5}
          fill="yellow"
          stroke="#666"
          strokeWidth={1}
          style={{ cursor: 'pointer' }}
          onMouseDown={(e) => startRotate(e, element.id)}
        />
      )}
    </g>
  );

  return (
    <>
      <svg 
        width={width} 
        height={height} 
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onMouseMove={(e) => {
          handleMouseMove(e);
          handleRotate(e);
          handleDrag(e);
        }}
        onMouseUp={() => {
          setResizing(null);
          setRotating(null);
          setDragging(null);
        }}
        onClick={handleBackgroundClick}
        className="grid"
        style={{ userSelect: 'none' }}
      >
        {renderGridNumbers()}
        {/* Platform boxes */}
        {elements
          .filter(element => element.type === 'box' && element.boxType === 'platform')
          .map((element) => renderElement(element))}
        
        {/* Tracks */}
        {elements
          .filter(element => element.type === 'track')
          .map((element) => renderElement(element))}
        
        {/* Flyovers */}
        {elements
          .filter(element => element.type === 'flyover')
          .map((element) => renderElement(element))}
        
        {/* Amenity boxes */}
        {elements
          .filter(element => element.type === 'box' && element.boxType === 'amenity')
          .map((element) => renderElement(element))}
      </svg>

      {/* Context Menu */}
      {contextMenu && (
        <div 
          className="context-menu"
          style={{
            position: 'fixed',
            top: contextMenu.y,
            left: contextMenu.x,
          }}
        >
          <button onClick={() => deleteElement(contextMenu.elementId)}>
            Delete Element
          </button>
        </div>
      )}
    </>
  );
}

export default Grid;