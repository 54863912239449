import React from 'react';

function Toolbar({ onSVGImport }) {
  const elements = [
    { 
      type: 'box', 
      width: 900, 
      height: 100, 
      label: 'Platform', 
      color: '#59a4ff',
      isBox: true,
      boxType: 'platform'
    },
    { 
      type: 'box', 
      width: 100, 
      height: 50, 
      label: 'Amenity', 
      color: '#4CAF50',
      isBox: true,
      boxType: 'amenity'
    },
    { 
      type: 'track', 
      width: 150, 
      height: 20, 
      label: 'Track' 
    },
    { 
      type: 'flyover', 
      width: 200, 
      height: 50, 
      label: 'Flyover' 
    }
  ];

  const handleDragStart = (e, element) => {
    const dragGhost = document.createElement('div');
    dragGhost.style.position = 'absolute';
    dragGhost.style.top = '-1000px';
    document.body.appendChild(dragGhost);
    e.dataTransfer.setDragImage(dragGhost, 0, 0);
    
    e.dataTransfer.setData('text', JSON.stringify(element));
    
    setTimeout(() => {
      document.body.removeChild(dragGhost);
    }, 0);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const svgContent = event.target.result;
        onSVGImport(svgContent);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="toolbar">
      <div className="toolbar-section">
        <label className="import-button">
          Import SVG
          <input
            type="file"
            accept=".svg"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </label>
      </div>
      <div className="toolbar-section">
        {elements.map((element, index) => (
          <div
            key={index}
            className="toolbar-item"
            draggable
            onDragStart={(e) => handleDragStart(e, element)}
          >
            {element.isBox && (
              <svg width={50} height={50}>
                <rect 
                  width={element.boxType === 'platform' ? 40 : 30} 
                  height={element.boxType === 'platform' ? 20 : 30} 
                  x={5} 
                  y={15} 
                  fill={element.color}
                  stroke="black"
                  strokeWidth="1"
                />
              </svg>
            )}
            {element.type === 'track' && (
              <svg width={50} height={50}>
                <line x1={5} y1={25} x2={45} y2={25} stroke="#000" strokeWidth={4} />
              </svg>
            )}
            {element.type === 'flyover' && (
              <svg width={50} height={50}>
                <path
                  d={`M 5 35 Q 25 15 45 35`}
                  fill="none"
                  stroke="#666"
                  strokeWidth={4}
                />
              </svg>
            )}
            <span>{element.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Toolbar;